/* bengali */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1abc4acc3608ccdd-s.woff2) format('woff2');
  unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2, U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7, U+200C-200D, U+20B9, U+25CC, U+A8F1;
}
/* latin-ext */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a29312ee6c3787aa-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f8e18c958c99c036-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* bengali */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b799e40d1da4cba6-s.woff2) format('woff2');
  unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2, U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7, U+200C-200D, U+20B9, U+25CC, U+A8F1;
}
/* latin-ext */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/96401ae653786a7e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bb185c3b111b9a6e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* bengali */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/88f8bd08eee39201-s.woff2) format('woff2');
  unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2, U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7, U+200C-200D, U+20B9, U+25CC, U+A8F1;
}
/* latin-ext */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d2f8c81c8882f57c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3f70dd7dc665a918-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* bengali */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ffe82e8670ea94ea-s.woff2) format('woff2');
  unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2, U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7, U+200C-200D, U+20B9, U+25CC, U+A8F1;
}
/* latin-ext */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b74fc4adaa0fed39-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d550ab7c3b0775b5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* bengali */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a2026c2855f26d92-s.woff2) format('woff2');
  unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2, U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7, U+200C-200D, U+20B9, U+25CC, U+A8F1;
}
/* latin-ext */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e1ea3edaff836893-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Hind_Siliguri_2ff298';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a9362c7696318d6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Hind_Siliguri_Fallback_2ff298';src: local("Arial");ascent-override: 116.79%;descent-override: 52.43%;line-gap-override: 0.00%;size-adjust: 95.56%
}.__className_2ff298 {font-family: '__Hind_Siliguri_2ff298', '__Hind_Siliguri_Fallback_2ff298';font-style: normal
}

.styles_ul__8V1vZ {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

